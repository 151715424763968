import React, {useState, useEffect, useRef} from "react"
import styled from 'styled-components';
import LandingPage from 'src/components/landing-page/LandingPage'

const IndexPage = ({
  ...rest
}) => {

  return (
    <LandingPage
      productTerm='CV Builder'
      pageName='Home - CV Templates'
      resumeTerm={{
        lowercasedSingular: 'CV',
        capitalizedSingular: 'CV',
        lowercasedPlural: 'CVs',
        capitalizedPlural: 'CVs',
      }}
      {...rest}
    />
  )
}

export default IndexPage
